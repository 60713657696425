.defaults {
  cursor: pointer;
  transition: ease 0.225s;
  color: var(--shallow-sea);
}

.big {
  font-size: 20px;
}

.small {
  font-size: 13px;
}
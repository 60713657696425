.defaults {
  white-space: nowrap;
  transition: ease 0.225s;
  border: none;
  letter-spacing: -0.2px;
  cursor: pointer;
  font-family: "Inter Regular";
  font-size: 15px;
  border-width: 0px;
  border-radius: 6px;
  display: flex;
  align-self: baseline;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--neutral-400);
  padding: 16px 24px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  min-height: 55px;
  max-height: 55px;
  -webkit-tap-highlight-color: transparent;
}

button:focus, 
button:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.linkElement {
  width: 100%;
}

.big {
  min-height: 65px;
  max-height: 65px;
  font-size: 18px;
  padding: 22px 32px;
}

.small {
  min-height: 45px;
  max-height: 45px;
  padding: 12px 20px;
}

@media screen and (max-width: 670px) {
  .big {
    font-size: 16px;
    padding: 18px;
  }
}

@media screen and (max-width: 430px) {
  .big {
    width: 100%;
  }
}

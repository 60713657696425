.container {
  padding: 0px 64px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--neutral-1000);
  z-index: 2;
}

.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rightContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.wrapper {
  padding: 24px 0px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--neutral-800);
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 105px;
  pointer-events: none;
}

@media screen and (max-width: 670px) {
  .container {
    padding: 0px 24px;
  }
}

@import "~rc-drawer/assets/index.css";

:root {
  --neutral-100: rgb(16, 16, 16);
  --neutral-200: rgb(32, 32, 32);
  --neutral-300: rgb(48, 48, 48);
  --neutral-400: rgb(81, 81, 81);
  --neutral-500: rgb(159, 159, 159);
  --neutral-600: rgb(184, 184, 184);
  --neutral-700: rgb(200, 200, 200);
  --neutral-800: rgb(225, 225, 225);
  --neutral-900: rgb(243, 243, 243);
  --neutral-1000: rgb(255, 255, 255);
  --deep-sea: rgba(29, 82, 254, 1);
  --shallow-sea: rgba(50, 98, 254, 1);
}

@font-face {
  font-family: 'Inter ExtraLight';
  src: url('/public/assets/typefaces/Inter/ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: 100;
}

@font-face {
  font-family: 'Inter Light';
  src: url('/public/assets/typefaces/Inter/Light.ttf') format('truetype');
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: 'Inter Thin';
  src: url('/public/assets/typefaces/Inter/Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: 300;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('/public/assets/typefaces/Inter/Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: 'Inter Medium';
  src: url('/public/assets/typefaces/Inter/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('/public/assets/typefaces/Inter/SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('/public/assets/typefaces/Inter/Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: 700;
}

@font-face {
  font-family: 'Inter ExtraBold';
  src: url('/public/assets/typefaces/Inter/ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: 'Inter Black';
  src: url('/public/assets/typefaces/Inter/Black.ttf') format('truetype');
  font-weight: 900;
  font-style: 900;
}

@font-face {
  font-family: 'Space Grotesk Light';
  src: url('/public/assets/typefaces/Space_Grotesk/Light.ttf') format('truetype');
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: 'Space Grotesk Regular';
  src: url('/public/assets/typefaces/Space_Grotesk/Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: 'Space Grotesk Medium';
  src: url('/public/assets/typefaces/Space_Grotesk/Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: 'Space Grotesk SemiBold';
  src: url('/public/assets/typefaces/Space_Grotesk/SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'Space Grotesk Bold';
  src: url('/public/assets/typefaces/Space_Grotesk/Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: 700;
}

.body {
  font-family: 'Space Grotesk Regular';
}

.drawer {
  
}

.drawer-open {
  
}
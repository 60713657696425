.container {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: var(--neutral-1000);
  z-index: 2;
  height: 150px;
  padding: 0px 64px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: 125px;
  pointer-events: none;
}

.fixedLogo {
  position: fixed;
  top: 24px;
  left: 24px;
}

.buttonContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.desktopButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.mobileButtonContainer {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: -7px;
}

.mobileMenu {
  z-index: 14;
}

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  transition: transform 0.3s ease-out;
  transform: translateX(100%);
}

.drawerOpen {
  transform: translateX(0);
}

@media screen and (max-width: 670px) {
  .desktopButtonContainer {
    display: none;
  }

  .mobileButtonContainer {
    display: flex;
  }

  .container {
    padding: 24px;
    height: 71px;
  }

  .logo {
    width: 105px;
  }
}

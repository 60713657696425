.defaults {
  color: var(--neutral-500);
  font-size: 16px;
  line-height: 180%;
  margin: 0;
}

.small {
  font-size: 13px !important;
  max-width: 600px;
}

.big {
  font-size: 20px !important;
  max-width: 600px;
}

b {
  color: var(--neutral-400);
}

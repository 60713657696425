.defaults {
}

.small {
  width: 8px;
  height: 8px;
}

.medium {
  width: 16px;
  height: 16px;
}

.large {
  width: 32px;
  height: 32px;
}
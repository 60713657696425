.container {
  margin-top: 124px;
  margin-bottom: 50px;
  padding: 128px 32px;
}

.message {
  text-align: center;
  max-width: 450px;
}

@media screen and (max-width: 670px) {
  .container {
    margin-top: 74px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 430px) {
  .waitlistContainer {
    width: 100%;
    flex-direction: column !important;
    row-gap: 24px;
  }
}
